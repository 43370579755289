import React from "react"

import { graphql } from "gatsby"
import styled from "styled-components"

import { Stack } from "@kiwicom/orbit-components/lib/"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const TextContent = styled.div`
  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
  }
  h3 {
    font-size: 17px;
    margin-top: 0px;
    font-weight: 600;
    line-height: 1.4;
    color: ${(props) => props.theme.orbit.paletteProductDark};
  }
  a {
    color: inherit;
    text-decoration: underline;
  }
  a:hover {
    color: ${(props) => props.theme.orbit.paletteProductDark};
  }
  p {
    line-height: 1.55;
  }
  li {
    margin-bottom: 10px;
  }
  *:last-child {
    margin-bottom: 0px;
  }
  *:first-child {
    margin-top: 0px;
  }
`

const Page = ({ data }) => {
  const diversion = data.commonDiversionsYaml
  return (
    <DriveLayout title={`${diversion.title} Diversion`}>
      <Hero title={diversion.title} subtitle={diversion.subtitle} />
      <Container size="medium">
        <EmberCard>
          <EmberCardSection>
            <Stack>
              <TextContent>
                <div
                  dangerouslySetInnerHTML={{ __html: diversion.introduction }}
                />
              </TextContent>
              <iframe
                src={diversion.google_map_embed_url}
                width="100%"
                height="400"
                style={{ border: "none" }}
              />
            </Stack>
          </EmberCardSection>
          {diversion.directions && (
            <EmberCardSection title="Directions">
              <TextContent>
                <div
                  dangerouslySetInnerHTML={{ __html: diversion.directions }}
                />
              </TextContent>
            </EmberCardSection>
          )}
        </EmberCard>
      </Container>
    </DriveLayout>
  )
}

export const query = graphql`
  query ($id: String) {
    commonDiversionsYaml(id: { eq: $id }) {
      title
      subtitle
      introduction
      google_map_embed_url
      directions
    }
  }
`

export default Page
